import React, { useState } from 'react';
import { Container, Nav, Navbar, NavDropdown, Button } from 'react-bootstrap';
import './navbar.css';

function BasicNavbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Navbar expand="lg" sticky="top" className="navbar">
      <Container>
        <Navbar.Brand href="index.html" className="navbar-brand">TechMetrica</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className="nav-link">Home</Nav.Link>
            <Nav.Link href="#about" className="nav-link">About Us</Nav.Link>
            <Nav.Link href="#services" className="nav-link">Services</Nav.Link>
            <Nav.Link href="#contact" className="nav-link">Contact Us</Nav.Link>
            <NavDropdown
              title="Products"
              id="basic-nav-dropdown"
              className={`nav-item ${dropdownOpen ? 'show' : ''}`}
              onClick={handleDropdownToggle}
            >
              <NavDropdown.Item href="#action/3.1" className="dropdown-item">Eduok School Management</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className="dropdown-item">Eduok College Management</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className="dropdown-item">Office Management Software</NavDropdown.Item>
            </NavDropdown>
            <Button variant="light" className="get-in-touch-button">Get in Touch</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicNavbar;
