import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./hero.css";

const CarouselComponent = () => {
  return (
    <div className="carousel-wrapper">
      <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
        {/* Hide indicators */}
        <ol className="carousel-indicators">
          <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active" aria-current="true" aria-label="First slide"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
          <li data-bs-target="#carouselId" data-bs-slide-to="2" aria-label="Third slide"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src="./carousel-1.jpg" className="d-block w-100" alt="First slide" />
            <div className="carousel-caption">
              <div className="overlay"></div>
              <div className="content">
                <h1 className="display-4">Software Solutions & Support Services</h1>
                <p>Drive Innovation With Industry-Experienced Software Experts</p>
                <a href="#" className="btn btn-primary btn-lg">Learn More</a>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src="./carousel-2.jpg" className="d-block w-100" alt="Second slide" />
            <div className="carousel-caption">
              <div className="overlay"></div>
              <div className="content">
                <h1 className="display-4">Creative Solutions</h1>
                <p>Innovate with us.</p>
                <a href="#" className="btn btn-primary btn-lg">Explore More</a>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src="./hero-image.jpg" className="d-block w-100" alt="Third slide" />
            <div className="carousel-caption">
              <div className="overlay"></div>
              <div className="content">
                <h1 className="display-4">Creative Solutions</h1>
                <p>Innovate with us.</p>
                <a href="#" className="btn btn-primary btn-lg">Explore More</a>
              </div>
            </div>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default CarouselComponent;
