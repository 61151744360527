import React, { useState } from 'react';
import './FAQ.css'; // Import the CSS file

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className="faq-layout">
        <div className="faq-support">
          <h4 className="faq-subtitle">Support</h4>
          <h3 className="faq-title">Frequently Asked Questions</h3>
          <p className="faq-description">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium!
          </p>
        </div>
        <div className="faq-accordion">
          {[
            { question: "Why should I choose Techmetrica?", answer: "The Techmetrica Shop is built right into your account dashboard and is accessible immediately after signing up." },
            { question: "Do I need to change banks?", answer: "You do not need to change banks. Techmetrica integrates with your existing bank setup." },
            { question: "How can I order equipment?", answer: "Equipment orders can be placed directly through your account dashboard." },
            { question: "Do you offer volume discounts?", answer: "Yes, we offer volume discounts based on the quantity ordered." },
            { question: "How does signing up work?", answer: "Signing up is quick and easy. Just complete the registration form on our website." },
          ].map((item, index) => (
            <div
              key={index}
              className={`accordion-item ${openIndex === index ? 'open' : ''}`}
              onClick={() => handleToggle(index)}
            >
              <div className="accordion-header">
                <span className="accordion-title">{item.question}</span>
                <span className={`accordion-icon ${openIndex === index ? 'rotate' : ''}`}>+</span>
              </div>
              <div className={`accordion-body ${openIndex === index ? 'show' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
